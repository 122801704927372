import { BaseModel } from "../../../../../core/util/BaseModel";
import { observable, action, makeObservable } from "mobx";

export class AccordionContentModel extends BaseModel {
    @observable projectDetails: FP.Entities.IProjectStakeholder;
    @observable currentView: "impacts" | "concerns" = "impacts";
    /**
     *
     */
    constructor(project: FP.Entities.IProjectStakeholder) {
        super();
        makeObservable(this);
        this.projectDetails = project;
    }

    @action
    setCurrentView = (view: "impacts" | "concerns") => {
        this.currentView = view;
    }
}