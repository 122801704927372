import React, { useCallback, useState } from "react";
import { ActionTypeSettingsModel } from "./ActionTypeSettings_model";
import I18n from "../../../../../core/localization/I18n";
import { LinkButton, Button, ButtonTypes } from "../../../../../components/ui/Button";
import { SearchButton } from "../../../../../components/ui/SearchButton";
import { observer } from "mobx-react";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { TableView } from "../../../../../core/table/Table_view";
import { Pagination } from "../../../../../components/widgets/pagination/Pagination_view";
import { useCurrentOrganisation } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { Async } from "react-async";
import { Animations } from "../../../../../core/util/Animations";
import Pages from "../../../../../routes/InsightRoutes";

interface ActionTypeSettingsProps {
  model?: ActionTypeSettingsModel;
}

const ActionTypeSettings: React.FunctionComponent<ActionTypeSettingsProps> = observer(({ model: m }) => {
  const currentOrganisation = useCurrentOrganisation();
  const [model] = useState(() => m || new ActionTypeSettingsModel(currentOrganisation.id));

  const load = useCallback(async () => {
    await model.loadActionTypes();
  }, [model]);

  return (
    <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="row mb-4">
        <div className="col-lg-8">
          <h1>
            {currentOrganisation.name} | {I18n.t("phrases.manageActionTypes")}
          </h1>
          <p>{I18n.t("phrases.manageActionTypesDescription")}</p>
        </div>
        <div className="col-lg-4">
          <div className="d-flex justify-content-end">
            <LinkButton
              type={ButtonTypes.OUTLINE_PRIMARY}
              href={`${Pages.dashboards.index.generateLink(currentOrganisation.id)}`}
              className="float-right"
            >
              {I18n.t("phrases.closeSettings")}
            </LinkButton>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <Button id="ShowActionTypeFormButton" onClick={() => model.showFormModal()}>
            {I18n.t("phrases.createActionType")}
          </Button>

          <SearchButton
            id="SearchActionType"
            className={"float-right"}
            value={model.searchInputValue}
            onChange={model.handleSearchInputChange}
            onClose={model.resetSearchInput}
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <Async promiseFn={load}>
            <Async.Loading>
              <PositionedSpinner />
            </Async.Loading>
            <Async.Resolved>
              {() => (
                <>
                  <TableView model={model.tableModel} />
                  <Pagination model={model.paginationModel} />
                </>
              )}
            </Async.Resolved>
          </Async>
        </div>
      </div>
    </div>
  );
});

export { ActionTypeSettings };
