import { useState } from "react";
import { Button, ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import { useIsOrganisationAdmin } from "../../../../../core/auth/authorise";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { AdminSettingsModel } from "./AdminSettings_model";
import Pages from "../../../../../routes/InsightRoutes";
import I18n from "../../../../../core/localization/I18n";

export const AdminSettings: React.FC = () => {
  const organisationId = useCurrentOrganisationId();
  const isAdmin = useIsOrganisationAdmin(organisationId);
  const [model] = useState(() => new AdminSettingsModel());

  return (
    <div className="admin-settings mt-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <h1>Admin Settings</h1>
          </div>
          <div className="col-lg-4">
            <div className="d-flex justify-content-end">
              <LinkButton
                type={ButtonTypes.OUTLINE_PRIMARY}
                href={`${Pages.dashboards.index.generateLink(organisationId)}`}
                className="float-right"
              >
                {I18n.t("phrases.closeSettings")}
              </LinkButton>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {isAdmin && (
              <Button onClick={() => model.updateActionRagStatus()} type={ButtonTypes.OUTLINE_PRIMARY}>
                Update Rag
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
