import * as _ from "lodash";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import PermissionsContext from "../../../../../contexts/permissions/PermissionsContext";
import I18n from "../../../../../core/localization/I18n";
import { ITableHeaderModel } from "../../../../../core/table/ITableHeaderModel";
import { ITableConfig } from "../../../../../core/table/ITableModel";
import { ITableRowModel } from "../../../../../core/table/ITableRowModel";
import { AddTableColumnSort } from "../../../../../core/table/TableHeader_view";
import { convertStakeholderToName } from "../../../../../core/util/Helpers";
import { Enums } from "../../../../../enums";
import { StakeholdersSettingsModel } from "./StakeholdersSettings_model";

export const GetStakeholderTableConfig = (
  parentModel: StakeholdersSettingsModel
): ITableConfig<FP.Entities.IStakeholder> => {
  let actions = [];
  const canContributeOrg = PermissionsContext.canContributeOrg(parentModel.orgId);
  if (canContributeOrg) {
    actions.push(
      {
        id: "action1",
        label: I18n.t("phrases.edit"),
        onAction: (ev, row: ITableRowModel) => {
          if (row.rowObject.stakeholderType === Enums.StakeholderType.INDIVIDUAL) {
            parentModel.showStakeholderFormModal(row.rowObject);
            return;
          }
          parentModel.showAudienceFormModal(row.rowObject);
        },
        componentProps: {
          type: "link"
        },
        rendersIn: "Button"
      },
      {
        id: "action2",
        label: I18n.t("phrases.delete"),
        onAction: (ev, row: ITableRowModel) => {
          parentModel.showStakeholderConfirmDeleteModal(row.rowObject);
        },
        componentProps: {
          type: "link"
        },
        rendersIn: "Button"
      }
    );
  }
  return {
    actions,
    colHeaders: [
      {
        key: "firstName,lastname",
        content: <h4 className="mb-0">{I18n.t("table.name")}</h4>,
        selector: obj => {
          return (
            <p className="mb-0">
              <Icon symbol={IconSymbols.ChevronRight} className="mr-2" />
              {convertStakeholderToName(obj)}
            </p>
          );
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, parentModel.tableModel, parentModel.filterIndModel);
        },
        showSortIcons: true
      },
      {
        key: "email",
        content: <h4 className="mb-0">{I18n.t("table.email")}</h4>,
        selector: obj => {
          return <p className="mb-0 text-break">{obj.email}</p>;
        },
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, parentModel.tableModel, parentModel.filterIndModel);
        },
        showSortIcons: true
      },
      {
        key: "businessArea.Name",
        content: <h4 className="mb-0">{I18n.t("table.businessArea")}</h4>,
        selector: (obj: FP.Entities.IStakeholder) => obj.businessArea?.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(
            model,
            parentModel.tableModel,
            parentModel.filterIndModel,
            "stakeholder.businessArea.Name"
          );
        },
        showSortIcons: true
      },
      {
        key: "role.Name",
        content: <h4 className="mb-0">{I18n.t("table.role")}</h4>,
        selector: (obj: FP.Entities.IStakeholder) => obj.role?.name,
        action: function (model: ITableHeaderModel) {
          AddTableColumnSort(model, parentModel.tableModel, parentModel.filterIndModel);
        },
        showSortIcons: true
      }
    ] as any[],
    onRowClick: (row: ITableRowModel) => {
      // let st = row.rowObject;
      // appHistory.push(`/organisations/${parentModel.orgId}/stakeholders/${st.id}`);
    },
    tableProps: { id: "StakeholderTableSettings" }
  };
};

export const GetAudienceTableConfig: (parentModel: StakeholdersSettingsModel) => ITableConfig<any> = (
  parentModel: StakeholdersSettingsModel
) =>
  ({
    actions: [
      {
        id: "action1",
        label: I18n.t("phrases.edit"),
        onAction: (ev, row: ITableRowModel) => {
          if (row.rowObject.stakeholderType === Enums.StakeholderType.INDIVIDUAL) {
            parentModel.showStakeholderFormModal(row.rowObject);
            return;
          }
          parentModel.showAudienceFormModal(row.rowObject);
        },
        componentProps: {
          type: "link"
        },
        rendersIn: "Button"
      },
      {
        id: "action2",
        label: I18n.t("phrases.delete"),
        onAction: (ev, row: ITableRowModel) => {
          parentModel.showStakeholderConfirmDeleteModal(row.rowObject);
        },
        componentProps: {
          type: "link"
        },
        rendersIn: "Button"
      }
    ],
    colHeaders: [
      {
        key: "user",
        content: <h4 className="mb-0">{I18n.t("table.name")}</h4>,
        onClick: function () {
          parentModel.tableModel.config.colHeaders[0].content = "Title";
        },
        action: function (model: ITableHeaderModel) {
          model.toggleSort();
          let data = parentModel.tableModel.config.initData.slice();
          data = _.orderBy(data, [model.config.selector], [model.isSortAsc ? "asc" : "desc"]);
          parentModel.tableModel.setData(data);
        },
        selector: obj => {
          return (
            <>
              <p className="mb-0">
                <Icon symbol={IconSymbols.ChevronRight} className="mr-2" />
                {obj.firstName}
              </p>
            </>
          );
        }
      },
      {
        key: "audienceCount",
        content: <h4 className="mb-0">{I18n.t("table.audienceCount")}</h4>,
        selector: (obj: FP.Entities.IStakeholder) => Math.abs(obj.audienceCount)
      },
      {
        key: "businessArea",
        content: <h4 className="mb-0">{I18n.t("table.businessArea")}</h4>,
        selector: "businessArea.name"
      }
    ],
    onRowClick: (row: ITableRowModel) => {
      // let st = row.rowObject;
      // appHistory.push(`/organisations/${parentModel.orgId}/stakeholders/${st.id}`);
    },
    tableProps: {}
  } as any);
