import * as React from "react";
import { OrganisationSettingsModel } from "./OrganisationSettings_model";
import { ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import { observer } from "mobx-react";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import I18n from "../../../../../core/localization/I18n";
import { SingleForm } from "../../../forms/singleFormModel/SingleForm_view";
import { Animations } from "../../../../../core/util/Animations";
import { Panel } from "../../../../../components/ui/Panel";
import Pages from "../../../../../routes/InsightRoutes";
import {
  useCurrentOrganisation,
  useCurrentOrganisationId
} from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { useRefreshClaims } from "../../../../../core/auth/authorise";
import { useNavigate } from "react-router-dom";

export interface OrganisationSettingsProps {}

const OrganisationSettings: React.FunctionComponent<OrganisationSettingsProps> = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const organisation = useCurrentOrganisation();
  const refreshClaim = useRefreshClaims();
  const navigate = useNavigate();
  const [model] = React.useState(
    () => new OrganisationSettingsModel(navigate, refreshClaim, organisationId, organisation)
  );

  React.useEffect(() => {
    model.onMount();
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div className={`container-fluid py-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="row mb-4">
        <div className="col-lg-8">
          <h1>
            {organisation.name} | {I18n.t("phrases.manageOrganisation")}
          </h1>
          <p>{I18n.t("phrases.manageOrganisationDescription")}</p>
        </div>
        <div className="col-lg-4">
          <div className="d-flex justify-content-end">
            <div className="d-flex justify-content-end">
              <LinkButton
                type={ButtonTypes.OUTLINE_PRIMARY}
                href={`${Pages.dashboards.index.generateLink(model.organisation.id)}`}
                className="float-right"
              >
                {I18n.t("phrases.closeSettings")}
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Panel.Panel hasBorderRadius={true} type={Panel.PanelTypes.OUTLINES} className="p-3">
            <SingleForm model={model.formModel} />
          </Panel.Panel>
        </div>
      </div>
    </div>
  );
});

export { OrganisationSettings };
