import React from "react";
import { observer } from "mobx-react";
import { useState, useCallback } from "react";
import { useCurrentOrganisation } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { Animations } from "../../../../../core/util/Animations";
import { ImpactTypeSettingsModel } from "./ImpactTypeSettings_model";
import { Async } from "react-async";
import { LinkButton, Button, ButtonTypes } from "../../../../../components/ui/Button";
import { Pagination } from "../../../../../components/widgets/pagination/Pagination_view";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import I18n from "../../../../../core/localization/I18n";
import { TableView } from "../../../../../core/table/Table_view";
import Pages from "../../../../../routes/InsightRoutes";

interface ImpactTypeSettingsProps {
  model?: ImpactTypeSettingsModel;
}

const ImpactTypeSettings: React.FC<ImpactTypeSettingsProps> = observer(({ model: m }) => {
  const currentOrganisation = useCurrentOrganisation();
  const [model] = useState(() => m || new ImpactTypeSettingsModel(currentOrganisation.id));

  const load = useCallback(async () => {
    await model.loadImpactTypes();
  }, [model]);

  return (
    <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="row mb-4">
        <div className="col-lg-8">
          <h1>
            {currentOrganisation.name} | {I18n.t("phrases.manageImpactTypes")}
          </h1>
          <p>{I18n.t("phrases.manageImpactTypesDescription")}</p>
        </div>
        <div className="col-lg-4">
          <div className="d-flex justify-content-end">
            <LinkButton
              type={ButtonTypes.OUTLINE_PRIMARY}
              href={`${Pages.dashboards.index.generateLink(currentOrganisation.id)}`}
              className="float-right"
            >
              {I18n.t("phrases.closeSettings")}
            </LinkButton>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <Button id="ShowActionTypeFormButton" type={ButtonTypes.PRIMARY} onClick={() => model.showFormModal()}>
            {I18n.t("phrases.createImpactType")}
          </Button>

          {/* <SearchButton
          id="SearchActionType"
          className={"float-right"}
          value={model.searchInputValue}
          onChange={model.handleSearchInputChange}
          onClose={model.resetSearchInput}
        /> */}
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <Async promiseFn={load}>
            <Async.Loading>
              <PositionedSpinner />
            </Async.Loading>
            <Async.Resolved>
              {() => (
                <>
                  <TableView model={model.tableModel} />
                  <Pagination model={model.paginationModel} />
                </>
              )}
            </Async.Resolved>
          </Async>
        </div>
      </div>
    </div>
  );
});

export { ImpactTypeSettings };
